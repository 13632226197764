<template>
  <v-app>
    <template v-if="submited == 0">
      <template v-if="!formLoading">
        <v-container>
          <div class="parent-wrapper">
            <div class="review-screen">
              <div class="company-profile">
                <img
                  src="https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/SL012021VF/files/image/png/MwRhbCQB4IY4bY5pEnlcZiY6mjRT6BEtUz5ss4HM.png"
                  alt="logo"
                  class="img-fluid"
                />
                <h3>Genic teams</h3>
              </div>
              <h4>Rate your experience!</h4>
              <p>
                We love to hear from you! How's your experience with the
                service?
              </p>
              <div>
                <div
                  class="text-center"
                  v-if="ratingType && ratingType == 'star'"
                >
                  <v-rating
                    v-model="starRating"
                    background-color="grey lighten-1"
                    :color="'orange'"
                    hover
                    size="60"
                  ></v-rating>
                </div>
                <div
                  class="text-center mb-3"
                  v-if="ratingType && ratingType == 'number'"
                >
                  <v-rating color="" v-model="starRating">
                    <template v-slot:item="props">
                      <v-avatar
                        class="mx-5"
                        style="border: 2px solid #bdbdbd"
                        :color="props.isFilled ? genColor(props.index) : ''"
                        size="36"
                        @click="props.click"
                      >
                        <span class="text-h6">{{ props.index + 1 }}</span>
                      </v-avatar>
                    </template>
                  </v-rating>
                </div>

                <p class="plz-rate">
                  Please rate your experience on a 5-{{ ratingType }} scale.
                </p>

                <TextAreaField
                  auto-grow
                  dense
                  filled
                  color="cyan"
                  placeholder="Description"
                  v-model="description"
                  solo
                  flat
                  row-height="25"
                  counter="250"
                />
                <div class="text-center">
                  <v-btn
                    width="200"
                    style="height: 40px !important"
                    color="blue darken-2 white--text rounded"
                    class="custom-bold-button"
                    depressed
                    :loading="submitedLoading"
                    :disabled="submitedLoading"
                    v-on:click="submitform"
                  >
                    Submit
                  </v-btn>
                </div>

                <div class="power_by mt-3">
                  <p>
                    Powered by
                    <img
                      src="https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/SL012021VF/files/image/png/MwRhbCQB4IY4bY5pEnlcZiY6mjRT6BEtUz5ss4HM.png"
                      alt="logo"
                      class="img-fluid"
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </v-container>
      </template>
      <template v-else>
        <div class="quotation-approved quotation-rejected my-20 text-center">
          <div class="card">
            <div
              style="
                border-radius: 200px;
                height: 300px;
                width: 300px;
                background: #f8faf5;
                margin: 0 auto;
              "
            >
              <v-icon
                style="
                  color: #f34747;
                  font-size: 100px;
                  margin-left: 0px;
                  /* margin: auto; */
                  margin-top: 53px !important;
                "
              >
                mdi-alert-circle-outline</v-icon
              >
            </div>
            <h1>Error</h1>
            <p>OOPS! Invalid URL</p>
          </div>
        </div>
      </template>
    </template>
    <template v-else>
      <v-container>
        <div class="parent-wrapper">
          <div class="review-screen">
            <div class="company-profile">
              <img
                src="https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/SL012021VF/files/image/png/MwRhbCQB4IY4bY5pEnlcZiY6mjRT6BEtUz5ss4HM.png"
                alt="logo"
                class="img-fluid"
              />
              <h3>Genic teams</h3>
            </div>
            <div class="review-screen">
              <h4>Thank you!</h4>
              <p>
                thanks for submitting your feedback! we really appreciate your
                time
              </p>
              <div class="plans-svg">
                <img
                  style="max-width: 100%"
                  src="https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/ACOM0623FSM/files/image/gif/LfRf8p9jN65cXcfVTSZe7f0MKWRkQtzGP89In3Mk.gif"
                  alt="img"
                  class="img-fluid"
                />
              </div>
            </div>
            <div>
              <div class="power_by mt-3">
                <p>
                  Powered by
                  <img
                    src="https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/SL012021VF/files/image/png/MwRhbCQB4IY4bY5pEnlcZiY6mjRT6BEtUz5ss4HM.png"
                    alt="logo"
                    class="img-fluid"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </v-container>
    </template>
  </v-app>
</template>
<script>
import {
  VERIFY_CUSTOMER_REVIEW,
  POST,
} from "@/core/services/store/request.module";
import TextAreaField from "@/view/components/app-component/TextAreaField.vue";

export default {
  data() {
    return {
      securityToken: null,
      tokenNotFound: false,
      formLoading: false,
      submitedLoading: false,
      validationErrorBag: new Array(),

      starRating: 3,
      visitId: 0,
      customerId: 0,
      ratingType: null,
      submited: 0,
      description: null,
    };
  },
  methods: {
    verifyCustomer() {
      const _this = this;
      _this.formLoading = true;
      _this.isVerified = false;

      _this.validationErrorBag = new Array();

      _this.$store
        .dispatch(VERIFY_CUSTOMER_REVIEW, {
          url: "customer-review/verify",
          token: _this.securityToken,
        })
        .then(({ data }) => {
          this.submited = this.lodash.toSafeInteger(data?.submited);
          this.customerId = this.lodash.toSafeInteger(data?.customer);
          this.ratingType = data?.rating_type;
          this.visitId = this.lodash.toSafeInteger(data?.visit);

          _this.formLoading = false;
        })
        .catch(({ response }) => {
          console.log(response);
          if (response && response.data && response.data.message) {
            _this.validationErrorBag = response.data.message.split("<br>");
          } else {
            _this.validationErrorBag.push(
              "Something went wrong, Please try again later."
            );
          }
        })
        .finally(() => {});
    },
    submitform() {
      const _this = this;

      _this.submitedLoading = true;
      _this.$store
        .dispatch(POST, {
          url: "submit-review",
          data: {
            visit: this.visitId,
            description: this.description,
            rating: this.starRating,
            rating_type: this.ratingType,
          },
        })
        .then(() => {
          _this.verifyCustomer();
          _this.submitedLoading = false;
        })
        .catch(({ response }) => {
          if (response && response.data && response.data.message) {
            _this.validationErrorBag = response.data.message.split("<br>");
          } else {
            _this.validationErrorBag.push(
              "Something went wrong, Please try again later."
            );
          }
          _this.submitedLoading = false;
        });
    },
    genColor(index) {
      const colors = [
        "orange text-white",
        "orange text-white",
        "orange text-white",
        "orange text-white",
        "orange text-white",
      ];
      return colors[index];
    },
  },
  created() {
    const _this = this;

    if (_this.$route.query && _this.$route.query.token) {
      _this.securityToken = _this.$route.query.token;
    } else {
      _this.tokenNotFound = true;
    }
  },
  mounted() {
    if (this.securityToken) {
      this.verifyCustomer();
    }
  },
  computed: {
    backgroundImage() {
      return process.env.VUE_APP_BASE_URL + "media/error/bg1.jpg";
    },
  },
  components: {
    TextAreaField,
  },
};
</script>
<style lang="scss">
@import "@/assets/sass/pages/review/review.scss";
</style>
